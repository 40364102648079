@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body {
  margin: 0;
  font-size: 1em;
  font-family: -apple-system, BlinkMacSystemFont, "Quicksand", "Source Sans Pro", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Source Sans Pro", arial, sans-serif;
}
